import React from 'react';
import { Redirect, Route, useHistory } from 'react-router-dom';
import { useAuth } from './AuthContext';

const AuthenticatedRoute = ({ component: Component, ...rest }) => {
  const auth = useAuth();
  const history = useHistory();
  console.log('In authenticated route');

  if (auth.user.isLoggedIn) {
    console.log('User is logged in');
    return <Route {...rest} render={(props) => <Component {...props} />} />;
  } else {
    console.log('User not logged in. Redirecting');
    history.push('/login');
    return <Redirect to='/login' />;
  }
};

export default AuthenticatedRoute;
