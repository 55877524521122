import axios from 'axios';
import { ResolvePlugin } from 'webpack';
import { Project, ClientContactDetails, StatusUpdate } from './QuickStatusTypes';

const API_URL = 'http://192.168.1.2:3000';
// const API_URL = 'https://quick-status-api.herokuapp.com';
// const API_URL = 'https://rompledev.com';

let sayHello = (accessToken: string) => {
  return new Promise((resolve, reject) => {
    fetch(`${API_URL}/`, {
      method: 'GET',
      headers: { mode: 'cors', Authorization: `Bearer ${accessToken}` },
    })
      .then(resolve)
      .catch(reject);
  });
};

let getUserInfo = (accessToken: string, userUid: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = includeAuth(accessToken);
      const response = await axios.get(`${API_URL}/api/users/${userUid}`, headers);
      if (response.status === 200) {
        console.log(response);
        resolve(response.data);
      } else {
        reject(`Unable to fetch user info. Status ${response.status}`);
      }
    } catch (err) {
      console.error(err);
      reject(err);
    }
  });
};

let getProject = function (projectID: string, token?: string) {
  return new Promise((resolve, reject) => {
    fetch(`${API_URL}/api/project/${projectID}`, {
      method: 'GET',
      headers: { mode: 'cors', Authorization: `Bearer ${token}` },
    })
      .then((response) => response.json())
      .then((project: Project) => {
        console.log('Retrieved Project', project);
        resolve(project);
      })
      .catch((error) => console.log('Error Retrieving Project: ', error));
  });
};

let getProjectFromAirtable = function (projectID: string, token?: string) {
  return new Promise((resolve, reject) => {
    fetch(`${API_URL}/public/projects/airtable/${projectID}`, {
      method: 'GET',
      headers: { mode: 'cors' },
    })
      .then((response) => response.json())
      .then((project: Project) => {
        console.log('Retrieved Project', project);
        resolve(project);
      })
      .catch((error) => console.log('Error Retrieving Project: ', error));
  });
};

let getStatusUpdatesForProjectId = function (projectId: string, token?: string) {
  return new Promise((resolve, reject) => {
    fetch(`${API_URL}/api/projects/airtable/${projectId}`, {
      method: 'GET',
      headers: { mode: 'cors' },
    })
      .then((response) => response.json())
      .then((statusUpdates: StatusUpdate[]) => {
        console.log('Retrieved Updates', statusUpdates);
        resolve(statusUpdates);
      })
      .catch((error) => console.log('Error Retrieving Project: ', error));
  });
};

let submitNewClient = function (
  clientDetails: ClientContactDetails,
  userUid: string,
  accessToken: string,
) {
  return new Promise(async (resolve, reject) => {
    let config = includeAuth(accessToken);
    axios
      .post(
        `${API_URL}/api/clients`,
        {
          clientDetails: clientDetails,
          userUid: userUid,
        },
        config,
      )
      .then(resolve)
      .catch((error) => reject(error));
  });
};

let includeAuth = function (accessToken: string) {
  return {
    headers: { mode: 'cors', Authorization: `Bearer ${accessToken}` },
  };
};

let getTeamMembersForClient = function (clientId: string, accessToken: string) {
  return new Promise((resolve, reject) => {
    let config = includeAuth(accessToken);
    axios
      .get(`${API_URL}/api/clients/${clientId}/members`, config)
      .then((response) => {
        console.log(response.data);
        resolve(response.data);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

let signInUser = function (email: string, password: string) {
  return new Promise((resolve, reject) => {
    fetch(`${API_URL}/api/user/login`, {
      method: 'POST',
      headers: { mode: 'cors' },
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

export {
  getUserInfo,
  getProject,
  sayHello,
  submitNewClient,
  getTeamMembersForClient,
  getStatusUpdatesForProjectId,
  getProjectFromAirtable,
};
