import { makeStyles } from '@material-ui/core';
import React from 'react';
import { Img } from 'react-image';
import Carousel from 'react-material-ui-carousel';
import { Photo } from '../api/QuickStatusTypes';

export default function ImageCarousel(props) {
  const photos: Photo[] = props.photos;

  if (photos) {
    // console.log(photos[0].thumbnails!['large']);
    var maxHeight = Math.max(...photos.map((p) => p.thumbnails!['large'].height)) / 2;

    const useStyles = makeStyles({
      root: {
        height: maxHeight,
      },
      media: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    });

    var classes = useStyles();

    return (
      <Carousel
        className={classes.root}
        autoPlay={false}
        indicators={true}
        animation={'slide'}
        navButtonsAlwaysVisible={true}
      >
        {photos.map((photo, i) => (
          <Img
            className={classes.media}
            key={i}
            src={photo.thumbnails!['large'].url}
            height={maxHeight}
            onClick={() => {
              // console.log('Clicked:', photo);
              props.onClick(photo);
            }}
          />
        ))}
      </Carousel>
    );
  } else {
    return <div></div>;
  }
}
