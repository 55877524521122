import { Grid, Typography } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import HomeWorkOutlinedIcon from '@material-ui/icons/HomeWorkOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import React, { useEffect, useState } from 'react';
import { getTeamMembersForClient, getUserInfo } from '../api/QuickStatusAPI';
import { TeamMemberInfo } from '../api/QuickStatusTypes';
import { useAuth } from '../auth/AuthContext';
import OutlinedCard from '../components/OutlinedCard';
import TeamMemberTable from '../components/TeamMemberTable';

const SETTINGS = 'Settings';
const TEAM = 'Team';
const PROJECTS = 'Projects;';

const Projects = () => (
  <Grid container spacing={3}>
    <Grid item>
      <OutlinedCard />
    </Grid>
    <Grid item>
      <OutlinedCard />
    </Grid>
    <Grid item>
      <OutlinedCard />
    </Grid>
    <Grid item>
      <OutlinedCard />
    </Grid>
    <Grid item>
      <OutlinedCard />
    </Grid>
    <Grid item>
      <OutlinedCard />
    </Grid>
  </Grid>
);

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    height: '100%',
    padding: theme.spacing(3),
  },
}));

export default function ClientDashboard() {
  const classes = useStyles();
  const theme = useTheme();
  const auth = useAuth();
  const [activeApp, setActiveApp] = useState<string>('Projects');
  const [activeClientId, setActiveClientId] = useState<string>();
  const [teamMemberInfo, setTeamMemberInfo] = useState<TeamMemberInfo[]>([]);

  console.log(auth.user);

  useEffect(() => {
    console.log('using effect...');
    getUserInfo(auth.user.accessToken, auth.user.uid).then((userInfo: any) => {
      var clientId = userInfo.memberships[0].client_id;
      console.log('Found client id,', clientId);
      setActiveClientId(clientId);
      getTeamMembersForClient(clientId, auth.user.accessToken).then((members: any) => {
        const teamMembers: TeamMemberInfo[] = members.map((member) => ({
          name: member.email,
          email: member.email,
          role: member.role,
          status: 'Invited',
        }));
        setTeamMemberInfo(teamMembers);
      });
    });
  }, []);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position='fixed' className={classes.appBar}>
        <Toolbar>
          <Typography variant='h6' noWrap>
            Client Business Name
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant='permanent'
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor='left'
      >
        <div className={classes.toolbar} style={{ borderRadius: 0 }} />
        <Divider />

        <List>
          <ListItem button onClick={() => setActiveApp(PROJECTS)}>
            <ListItemIcon>
              <HomeWorkOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary='Projects' />
          </ListItem>
          <ListItem button onClick={() => setActiveApp(TEAM)}>
            <ListItemIcon>
              <GroupOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary='Team Members' />
          </ListItem>

          <Divider />

          <ListItem button onClick={() => setActiveApp(SETTINGS)}>
            <ListItemIcon>
              <SettingsOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary='Settings' />
          </ListItem>
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {activeApp === PROJECTS && <Projects></Projects>}
        {activeApp === TEAM && <TeamMemberTable teamMemberInfo={teamMemberInfo}></TeamMemberTable>}
        {activeApp === SETTINGS && <Typography>Settings...</Typography>}
      </main>
    </div>
  );
}
