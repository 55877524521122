import { Dialog, DialogContent, Divider, Grid } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import React, { useState } from 'react';
import { Img } from 'react-image';
import { Photo, StatusUpdate } from '../api/QuickStatusTypes';
import ImageCarousel from './ImageCarousel';

const NO_IMAGE_URL = '/public/thumbnails/no_image.jpg';
const FLAT_ICON_URL = '/public/thumbnails/multiple_images.png';

type Props = {
  update: StatusUpdate;
};

export default function StatusUpdateCard({ update }: Props) {
  const { projectName, status, statusUpdate, photos, expectedReturnDate } = update;

  const [bigPhoto, setBigPhoto] = useState<Photo | null>(null);

  var imageUrl;

  if (photos === undefined || photos.length == 0) {
    imageUrl = NO_IMAGE_URL;
  } else {
    let thumbnail = photos![0].thumbnails!['large'];
    imageUrl = thumbnail.url;
    let ratio = 300 / Math.max(thumbnail.height, thumbnail.width);
    var thumbnailHeight = thumbnail.height * ratio;
    var thumbnailWidth = thumbnail.width * ratio;
  }

  const hasPhotos: boolean = photos !== undefined && photos.length > 0;

  return (
    <Card variant='outlined'>
      <Grid container direction='column' justify='flex-end' spacing={1}>
        <Grid container direction='row' justify='space-between'>
          <Grid item>
            <Typography variant='subtitle2' gutterBottom>
              {status}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant='subtitle2' gutterBottom>
              {moment(update.created).format('dddd, MMMM Do YYYY, h:mm:ss a')}
            </Typography>
          </Grid>
        </Grid>

        <Grid item>
          <Typography
            variant='body1'
            style={{ alignContent: 'flex-end', verticalAlign: 'text-bottom' }}
          >
            {statusUpdate}
          </Typography>
        </Grid>

        {hasPhotos && (
          <Grid item>
            <Divider />
          </Grid>
        )}
        {hasPhotos && (
          <Grid item xs={12} style={{ marginBottom: 5 }}>
            <ImageCarousel thumbnails={true} onClick={setBigPhoto} photos={photos}></ImageCarousel>
          </Grid>
        )}
      </Grid>

      {bigPhoto !== null && (
        <Dialog
          maxWidth={false}
          fullScreen={false}
          open={bigPhoto !== null}
          onClose={() => setBigPhoto(null)}
        >
          <DialogContent>
            <Img src={bigPhoto.thumbnails!['large'].url}></Img>
          </DialogContent>
        </Dialog>
      )}
    </Card>
  );
}

StatusUpdateCard.defaultProps = {
  projectName: 'ProjectName',
  status: 'Status',
  statusUpdate: 'Status Update Text',
  photos: [],
};
