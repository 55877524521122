import { createMuiTheme, responsiveFontSizes } from '@material-ui/core';

const themeOverrides = {
  overrides: {
    MuiPaper: {
      root: {
        padding: '10px',
      },
    },
  },
};

var theme = responsiveFontSizes(createMuiTheme(themeOverrides));
theme.typography.body1 = {
  fontSize: '1.2rem',
  [theme.breakpoints.up('md')]: {
    fontSize: '1.75rem',
  },
};

theme.typography.subtitle2 = {
  fontSize: '1.25rem',
  '@media (min-width:600px)': {
    fontSize: '1.5rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.5rem',
  },
};

theme.typography.h1 = {
  fontSize: '1.5rem',
  '@media (min-width:600px)': {
    fontSize: '1.5rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2.0rem',
  },
};

theme.typography.h2 = {
  fontSize: '1.25rem',
  '@media (min-width:600px)': {
    fontSize: '1.5rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.25rem',
  },
};

theme.typography.h3 = {
  fontSize: '1.25rem',
  '@media (min-width:600px)': {
    fontSize: '1.5rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.75rem',
  },
};
const FastStatusTheme = theme;
export { FastStatusTheme };
