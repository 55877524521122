//https://kentcdodds.com/blog/authentication-in-react-applications

import {
  Button,
  CircularProgress,
  Grid,
  Link,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useAuth } from '../auth/AuthContext';

export default function LoginComponent(props) {
  const [userEmail, setUserEmail] = useState<string>('test@test.com');
  const [userPassword, setUserPassword] = useState<string>('Password1234');
  const [showRegister, setShowRegister] = useState<boolean>(false);

  const auth = useAuth();

  if (auth.user.isLoggedIn) {
    console.log('User logged in, redirection');
    return <Redirect to='/dashboard' />;
  }

  return (
    <Grid
      container
      direction='column'
      justify='center'
      alignItems='center'
      style={{ height: '100%' }}
    >
      <Grid item>
        <Paper style={{ width: 300, padding: 25 }}>
          <Grid container direction='column' alignItems='center' spacing={3}>
            <Grid item>
              <Typography variant='body1'></Typography>
            </Grid>
            <Grid item>
              <TextField
                required
                id='outlined-required'
                label='Email'
                value={userEmail}
                onChange={(e) => setUserEmail(e.target.value)}
                variant='outlined'
                style={{ width: 225 }}
              />
            </Grid>
            <Grid item>
              <TextField
                id='outlined-password-input'
                required
                label='Password'
                type='password'
                autoComplete='current-password'
                value={userPassword}
                onChange={(e) => setUserPassword(e.target.value)}
                variant='outlined'
                style={{ width: 225 }}
              />
            </Grid>
            {showRegister ? (
              <div>
                <Grid item>
                  {auth.waiting ? (
                    <CircularProgress />
                  ) : (
                    <Button
                      onClick={(e) => {
                        console.log('Click');
                        auth.signUpUser(userEmail, userPassword);
                      }}
                      variant='contained'
                      color='primary'
                      style={{ width: 225, marginBottom: 10 }}
                    >
                      Register
                    </Button>
                  )}
                </Grid>
                <Grid item>
                  <Typography variant='body2' align='center'>
                    <Link
                      href='#'
                      onClick={() => {
                        setShowRegister(false);
                      }}
                    >
                      Already registered? Sign in here!
                    </Link>
                  </Typography>
                </Grid>
              </div>
            ) : (
              <div>
                <Grid item>
                  {auth.waiting ? (
                    <CircularProgress />
                  ) : (
                    <Button
                      onClick={(e) => {
                        console.log('Click');
                        auth.signInUser(userEmail, userPassword);
                      }}
                      variant='contained'
                      color='primary'
                      style={{ width: 225, marginBottom: 10 }}
                    >
                      Sign In
                    </Button>
                  )}
                </Grid>
                <Grid item>
                  <Typography variant='body2' align='center'>
                    <Link
                      href='#'
                      onClick={() => {
                        setShowRegister(true);
                      }}
                    >
                      Click here to register
                    </Link>
                  </Typography>
                </Grid>
              </div>
            )}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
