import { Grid } from '@material-ui/core';
import React from 'react';
import { StatusUpdate } from '../api/QuickStatusTypes';
import StatusUpdateCard from './StatusUpdateCard';

type Props = {
  statusUpdates: StatusUpdate[] | undefined;
};
export default function StatusUpdateGrid({ statusUpdates }: Props) {
  console.log(statusUpdates);

  if (statusUpdates === undefined) {
    return <div></div>;
  }

  return (
    <Grid container direction='column' spacing={4} alignItems='stretch' justify='flex-start'>
      {statusUpdates!
        .filter((s) => s.readyForClient)
        .map((update, i) => {
          return (
            <Grid item xs={12} key={i} style={{ marginBottom: 10 }}>
              <StatusUpdateCard update={update}></StatusUpdateCard>
            </Grid>
          );
        })}
    </Grid>
  );
}
