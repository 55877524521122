import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { AuthProvider } from './auth/AuthContext';
import AuthenticatedRoute from './auth/AuthenticatedRoute';
import LoginComponent from './views/LoginComponent';
import ViewerApp from './views/ViewerApp';
import ClientDashboard from './views/ClientDashboard';
ReactDOM.render(
  <AuthProvider>
    <Router>
      <div>
        <Switch>
          {/* <Route path='/dashboard' component={ClientDashboard}></Route> */}
          <AuthenticatedRoute path='/dashboard' component={ClientDashboard}></AuthenticatedRoute>
          <Route path='/project/:id'>
            <ViewerApp />
          </Route>
          <Route path={['/', '/login']}>
            <LoginComponent />
          </Route>
        </Switch>
      </div>
    </Router>
  </AuthProvider>,
  document.getElementById('root'),
);
