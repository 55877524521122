import { Typography, Grid } from '@material-ui/core';
import React from 'react';

export default function Header({ text, date, returnDate }) {
  return (
    <div>
      <Typography variant='h4' color='textPrimary' gutterBottom align='center'>
        {text}
      </Typography>
      <Typography variant='subtitle2' color='textSecondary' gutterBottom style={{}}>
        Last Updated: {date}
      </Typography>
      {returnDate && (
        <Typography variant='subtitle2' color='textSecondary' style={{ margin: 0 }}>
          Expected Return: {returnDate}
        </Typography>
      )}
    </div>
  );
}
