/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'us-east-1',
  aws_cognito_identity_pool_id: 'us-east-1:039b2280-6515-455b-85f1-8b4c37e573c0',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_C35Dmo27C',
  aws_user_pools_web_client_id: '2761j5m6nfqpnmav4ifbr1dn7j',
};

export default awsmobile;
