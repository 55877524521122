import { Box, Grid, Paper, ThemeProvider, Typography } from '@material-ui/core';
import moment from 'moment';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { getProject, getProjectFromAirtable } from '../api/QuickStatusAPI';
import { Project, StatusUpdate } from '../api/QuickStatusTypes';
import Header from '../components/Header';
import StatusUpdateGrid from '../components/StatusUpdateGrid';
import { FastStatusTheme } from '../theme.js';

export default function ViewerApp() {
  // We can call useParams() here to get the params,
  // or in any child element as well!
  const [loaded, setLoaded] = useState(false);
  const [headerText, setHeaderText] = useState<String>('Project Status Updates');
  const [statusUpdates, setStatusUpdates] = useState<StatusUpdate[] | undefined>(undefined);
  const [currentProject, setCurrentProject] = useState<Project | undefined>(undefined);

  let { id } = useParams();
  if (id === undefined) {
    id = 'recOoCjcSj2gXhPFb';
  }

  if (!loaded) {
    // const id = 'recOoCjcSj2gXhPFb';
    getProjectFromAirtable(id).then((project: Project) => {
      console.log('Retrieved project');
      let sortedUpdates = project.statusUpdates.sort((a, b) =>
        moment(b.created).diff(moment(a.created)),
      );
      setStatusUpdates(sortedUpdates);
      setLoaded(true);
      setCurrentProject(project);
      setHeaderText(project.name);
    });
  }

  if (loaded) {
    if (statusUpdates !== undefined) {
      var lastUpdated = statusUpdates
        .map((s) => moment(s.created))
        .sort((a, b) => a.diff(b))[0]
        .format('dddd, MM/DD/YYYY, h:mm:ss a');

      var expectedReturnDate = moment
        .max(
          statusUpdates
            .filter((s) => s.expectedReturnDate !== null && s.expectedReturnDate != undefined)
            .map((s) => moment(s.expectedReturnDate)),
        )
        .format('dddd, MM/DD/YYYY, h:mm:ss a');
    } else {
      var lastUpdated = 'Unknown';
    }
  }

  const content = () => {
    return (
      <ThemeProvider theme={FastStatusTheme}>
        <Box>
          <Paper elevation={0} style={{ marginTop: 10, marginBottom: 30 }}>
            <Header text={headerText} date={lastUpdated} returnDate={expectedReturnDate} />
          </Paper>
          <StatusUpdateGrid statusUpdates={statusUpdates}></StatusUpdateGrid>
        </Box>
      </ThemeProvider>
    );
  };

  if (statusUpdates === undefined) {
    return <Typography variant='body1'> Loading... </Typography>;
  }

  return true ? (
    <Grid container direction='row' justify='space-between' alignItems='stretch'>
      <Grid item lg={3}></Grid>
      <Grid item lg={6}>
        {content()}
      </Grid>
      <Grid item lg={3}></Grid>
    </Grid>
  ) : (
    content()
  );
}
