import Amplify, { Auth } from 'aws-amplify';
import React, { useState } from 'react';
import { AuthenticatedUser } from '../api/QuickStatusTypes';
import awsconfig from './aws-exports';

Amplify.configure(awsconfig);
const defaultAuthState: AuthenticatedUser = {
  isLoggedIn: false,
  uid: '',
  email: '',
  accessToken: '',
};

const AuthContext = React.createContext({
  user: defaultAuthState,
  signInUser: (username: string, password: string) => Promise.resolve(),
  signOutUser: () => Promise.resolve(),
  signUpUser: (username: string, password: string) => Promise.resolve(),
  waiting: false,
});

const AuthProvider = (props) => {
  const [user, setUser] = useState<AuthenticatedUser>(defaultAuthState);
  const [waiting, setWaiting] = useState<boolean>(false);

  /**
   * 
   * @param username 
   * @param password 
   * 
   * return {
        user: CognitoUser;
            userConfirmed: boolean;
            userSub: string;
        }
   */
  async function signUpUser(username: string, password: string) {
    try {
      const { user } = await Auth.signUp({
        username,
        password,
        attributes: {
          email: username,
        },
      });
      console.log('Registered ', user);
      signInUser(username, password);
    } catch (error) {
      console.log('error signing up:', error);
    }
  }

  /**
   *
   * @param username
   * @param password
   */
  async function signInUser(username: string, password: string) {
    try {
      const user = await Auth.signIn(username, password);
      console.log('Signed in', user);
      const authUser: AuthenticatedUser = {
        isLoggedIn: true,
        email: user.attributes.email,
        accessToken: user.signInUserSession.accessToken.jwtToken,
        uid: user.attributes.sub,
      };
      console.log(authUser);
      setUser(authUser);
    } catch (error) {
      console.log('error signing in', error);
    }
  }

  async function signOutUser() {
    try {
      await Auth.signOut();
      setUser(defaultAuthState);
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }

  return (
    <AuthContext.Provider value={{ user, waiting, signInUser, signOutUser, signUpUser }}>
      {props.children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  return React.useContext(AuthContext);
};

export { AuthProvider, useAuth };
